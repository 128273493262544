<template>
  <div class="pbf grid_width harvests_boxs">


    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="sortOptions"
        />
    </div>

      
    <SkBasicRows 
      v-if="is_loading && start == 0"
      />

    <div class="harvest_list" 
      v-else-if="harvest_data?.items">
        <GeneralHarvest
          v-for="pro in harvest_data.items"
          :n_weight="pro.n_weight"
          :n_plant="pro.n_plant"
          :item_diary="pro.item_diary"
          :add_date="pro.add_date"
          :strain_general="pro.strain_general"
          :item_diary_seed="pro.item_product"
          :item_user="pro.item_user"
          />
    </div> 

    <ClientOnly>        
      <UiInfinityScroll 
        v-if="harvest_data?.items?.length >= limit"
        :is-loading="is_loading"
        ref="elInfinityScroll"/>
    </ClientOnly>

  

  </div>
</template>

<script setup lang="ts">

import { getTagsKeyword, getTagsKeys, getTagsSimple  } from '@/types/other'

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const isEnded = ref(false)
const {$api, $ga} = useNuxtApp()
const elInfinityScroll = ref(null)

const props = defineProps({
  inurl: {
    type: String,
    required: true
  },
  intags: {
    type: Object,
    required: false
  },
  brandId: {
    type: Number,
    default: null
  },
  productId: {
    type: Number,
    default: null
  },
  growerId: {
    type: Number,
    default: null
  },
  strainId: {
    type: Number,
    required: false
  }
})

var start = ref(0);
var limit = ref(20);
var query = ref('');

const sort = ref('create_desc');
const sortOptions = ref([
  {id:'rate_desc', tr: 'universal_sort_review', icon: 'desc'},    
  {id:'rate_asc', tr: 'universal_sort_review', icon: 'asc'},    
  {id:'create_desc', tr: 'universal_sort_creation_date', icon: 'desc'},
  {id:'create_asc', tr: 'universal_sort_creation_date', icon: 'asc'},
  {id:'az_desc', tr: 'universal_sort_az', icon: 'desc'},
  {id:'az_asc', tr: 'universal_sort_az', icon: 'asc'},
  {id:'gwatt_desc', tr: 'universal_sort_gwatt', icon: 'desc'},
  {id:'gwatt_asc', tr: 'universal_sort_gwatt', icon: 'asc'},
  {id:'gplant_desc', tr: 'universal_sort_gplant', icon: 'desc'},
  {id:'gplant_asc', tr: 'universal_sort_gplant', icon: 'asc'},
]);
var tagscount = ref([]);
var tagsselected = ref([]);
const tagspermanent = ref([]);
var tagsdefault = ref(null);
var list = ref([]);

 


if(props.strainId){
  tagspermanent.value.push('st:' + props.strainId);
}

if(props.growerId){
  tagspermanent.value.push('us:' + props.growerId);
}

if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.productId){
  tagspermanent.value.push('pr:' + props.productId);
}



const clearPagination = function(){     
  start.value = 0;  
}


const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}

 



const loadData = async function() {  
  const response = await $api.getUrl(props.inurl, {
    start: start.value,
    limit: limit.value,
    q: getTagsKeyword(props.intags, tagsselected.value),       
    sortable: sort.value,
    tagsall: getTagsKeys(props.intags),
    tags: getTagsSimple(props.intags, tagsselected.value, tagspermanent.value)
  });
  return response;  
}



const { pending: is_loading,  data: harvest_data } = await useLazyAsyncData('harvest_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0){
    harvest_data.value.items = [];
    isEnded.value = false;
  }
  
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  

  harvest_data.value.items = [...harvest_data.value.items, ...dt.items]
  // harvest_data.value.items_tag = [...harvest_data.value.items_tag, ...dt.items_tag]
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

</script>

<style scoped>

.sortb{
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.harvest_list{
  grid-area: harvest_list;
  gap: 0 2rem;
  -moz-column-count: 2;
  column-count: 2;
}
.harvest_list :deep(.hrow){
  background-color: transparent;
  border-bottom: 1px var(--un-element-color-gray-light) solid;
}

.harvest_list :deep(.hrow:nth-last-child(-n+2)) {
  border-bottom: none;
}

@container pb (max-width: 1000px){
  .harvest_list{
    -moz-column-count: 1;
    column-count: 1;
  }
  .harvest_list :deep(.hrow){ 
    border-bottom: 0;
  }
}
</style>
